@font-face {
  font-family: 'BeggarsExtended';
  src: url('BeggarsExtended.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WarpathExtended';
  src: url('WarpathExtended.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Augusta';
  src: url('Augusta.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body{
  margin: 0px;
}

iframe {
  height: 54vw; /* 100/56.25 = 560/315 = 1.778 */  
}

.App{
  font-family: "BeggarsExtended";
  font-size: 2.5rem;
  line-height: initial;
}

.bkimage{
  background-image: url('./img/background.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: fixed;
}

.main{
  position: absolute;
  width: 100%;
}

.imgtitle
{
  width: 100%;
  max-width: 728px;
}

.imgauto
{
  width: 100%;
}

.imgsmall
{
  max-width: 128px;
}

.imgborder
{
  border-block-start-color: gray;
  border-left-color: gray;
  border-right-color: silver;
  border-block-end-color: silver;
  border-style: solid;
}

.notm
{
  margin: 0;
}

.notp
{
  padding: 0;
}

.subtitle
{
  font-family: "Augusta";
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 0px #ff9125;
  margin-top: 16px;
  margin-bottom: 16px;
}

.ffaugusta
{
  font-family: "Augusta";
}

.ntext
{
  font-size: 3rem;
  color: white;
  text-shadow: 1px 1px 0px #ff9125;
}

.scrollbox
{
  width: auto;
  padding: 16px;
  background-color: #F5B784;
  border-color: #AE6C37;
  border-block-end-color: #823C3D;
  border-style: double;
  box-shadow: inset 4px 4px 0px 0px #FFE9C5,
              inset -4px -4px 0px 0px #FFE9C5, 
              6px 6px 6px 6px rgba(0, 0, 0, 0.2);
}

.imagebox
{
  width: auto;
  padding: 16px;
  background-color: #736c66;
  border-color: #2d2b29;
  border-block-end-color: #2d2b29;
  border-style: double;
  box-shadow: inset 4px 4px 0px 0px #969696, inset -4px -4px 0px 0px #4e4e4e, 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
}

.tc{
  text-align: center;
}

.bshadow{
  box-shadow: 6px 6px 6px 6px rgba(0, 0, 0, 0.2);
}

.black
{
  color: black;
}